import React from 'react';
import { FaLinkedin } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';

import package_json from '../package.json';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
                <ul className="footerMenu">
                    <li className="footerMenuItem firstItem">
                        © 2024 Skillware Labs, Inc. All rights reserved.
                    </li>
                    <li className="footerMenuItem">
                        <a href="mailto:contact@skillwarelabs.com" className="footerLink">
                            <MdEmail className="footerIcon"/>
                        </a>
                    </li>
                    <li className="footerMenuItem">
                    <a href="https://www.linkedin.com/company/104885505" className="footerLink">
                    
                        <FaLinkedin className="footerIcon"/>
                    </a>
                    </li>
                    <li className="footerMenuItem">
                        Version: {package_json.version}
                    </li>
                </ul>
        </footer>
    );
};

export default Footer;
